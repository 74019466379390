<template>
  <footer class="border-t-2 border-t-gray-200 border-double z-50 py-0 bg-white w-full">
    <SolutionsFooter v-if="!hideSolutionFooter" />
    <div class="px-2 py-2 justify-between footer-content">
      <!-- Logo on the left -->
      <div class="flex-shrink-0  hf-logo">
        <a href="https://www.hochfrequenz.de/" target="_blank" rel="noopener noreferrer">
          <img 
            src="/css/hochfrequenz/logo_weiss.png" 
            alt="Hochfrequenz Logo" 
            style="width: 160px; height: auto;"
          />
        </a>
      </div>
      <!-- Menu items in the center -->
      <div class="flex-grow text-center footer-menu">
        <p class="space-x-2 flex flex-wrap items-center m-0">
        <span>© {{ new Date().getFullYear() }} -</span>
        <DeploymentInfo class="inline-flex items-center" /> -
        <span>made with </span>
        <img 
          src="/css/hochfrequenz/icons/herz.svg"
          alt="heart"
          style="display: inline-block; width: 16px; height: 16px; margin: 0 2px; vertical-align: middle; filter: brightness(0) invert(1);"
        />
        <span> by</span>
        <a
          target="_blank"
          href="https://www.hochfrequenz.de"
          class="footer-link text-gray-800 hover:text-gray-500 transition-colors duration-200 px-1"
          style="font-weight: 800"
          >Hochfrequenz</a
        >
        <span>| powered by</span>
        <a
          class="ml-1 mr-2 hover:underline mono-font footer-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/hochfrequenz/ahbicht"
          >AHB_icht</a
        >
        <span>&amp;</span>
        <a
          class="ml-1 mr-2 hover:underline footer-link mono-font"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/Hochfrequenz/kohlrahbi"
          >kohlr_AHB_i</a
        >
        <span>|</span>
        <a
          target="_blank"
          href="https://www.hochfrequenz.de/datenschutz/"
          class="footer-link !important duration-200 px-1"
          >Datenschutz</a
        >
        <span>|</span>
        <a
          target="_blank"
          href="https://www.hochfrequenz.de/impressum/"
          class="footer-link !important duration-200 px-1"
          >Impressum</a
        >
        <span>|</span>
        <a
          target="_blank"
          href="https://www.hochfrequenz.de/kontakt/"
          class="footer-link !important transition-colors duration-200 px-1"
          >Kontakt</a
        >
        </p>
      </div>
      <!-- to compensate the padding caused by the logo on the left -->
      <div class="flex-shrink-0  hf-logo" style="width: 160px;" />
    </div>
  </footer>
</template>

<script>
import DeploymentInfo from "@/components/DeploymentInfo.vue";

import SolutionsFooter from "@/components/UpperFooter.vue";


export default {
  name: "AppFooter",
  components: {
    DeploymentInfo,
    SolutionsFooter,
  },
  props: {
    hideSolutionFooter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>