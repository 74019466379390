<template>
  <div class="navbar-default navbar-fixed-top w-full" id="navigation">
    <div class="container-fluid w-full px-4">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <a class="navbar-brand" href="/#body">
          <img
            src="/css/hochfrequenz/symbol_weiss.png"
            alt="HF Logo"
            class="navbar-logo"
          />
          <span class="text-xl ml-3">Bedingungsbaum</span>
        </a>
      </div>
      <!-- Input Fields Container -->
      <div class="input-fields-container">
        <!-- Format Version Input -->
        <div class="w-48 input-field-container">
          <v-autocomplete
            id="format-version-select"
            class="input-field"
            v-model="selectedEdifactFormatVersion"
            hide-details
            :items="AvailableEdifactFormatVersions"
            @change="evaluateExpression(expressionInput)"
            return-object
            outlined
            dense
            label="Formatversion"
          ></v-autocomplete>
          <v-btn
            class="info-button"
            small
            icon
            @click="showFormatVersionInfobox = true"
          >
          <img
              src="/css/hochfrequenz/icons/info.svg"
              alt="info"
              style="
                display: inline-block;
                width: 22px;
                height:22px;
                margin: 0 2px;
                filter: brightness(0) invert(1);
              "
            />
          </v-btn>

          <v-dialog v-model="showFormatVersionInfobox" width="600">
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>Formatversion</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="showFormatVersionInfobox = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="text-body-1 text-left">
                Um die richtigen Bedingungstexte anzeigen zu lassen, kann hier
                die entsprechende Formatversion ausgewählt werden. Wird bei
                Formatversion oder Nachrichtenformat "Keine Angabe" ausgewählt,
                dann werden nur die Nummern der Bedingungen ohne Text angezeigt.
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>

        <!-- Message Format Input -->
        <div class="w-48 input-field-container">
          <v-autocomplete
            id="message-format-select"
            class="input-field"
            v-model="selectedEdifactFormat"
            :items="AvailableEdifactFormats"
            @change="evaluateExpression(expressionInput)"
            hide-details
            return-object
            outlined
            dense
            label="Nachrichtenformat"
          ></v-autocomplete>
          <v-btn
            class="info-button"
            small
            icon
            @click="showMessageFormatInfobox = true"
          >
          <img
              src="/css/hochfrequenz/icons/info.svg"
              alt="info"
              style="
                display: inline-block;
                width: 22px;
                height:22px;
                margin: 0 2px;
                filter: brightness(0) invert(1);
              "
            />
          </v-btn>

          <v-dialog v-model="showMessageFormatInfobox" width="600">
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>Nachrichtenformat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="showMessageFormatInfobox = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="text-body-1 text-left">
                Um die richtigen Bedingungstexte anzeigen zu lassen, kann hier
                die entsprechende Formatversion ausgewählt werden. Wird bei
                Formatversion oder Nachrichtenformat "Keine Angabe" ausgewählt,
                dann werden nur die Nummern der Bedingungen ohne Text angezeigt.
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <!-- AHB-Bedingung Input -->
        <div class="w-64 input-field-container">
          <v-form @submit.prevent="evaluateExpression(expressionInput)">
            <div class="position-relative">
              <v-text-field
                id="ahb-bedingung-select"
                class="input-field"
                :items="suggestions"
                v-model="expressionInput"
                outlined
                dense
                label="AHB-Bedingung"
                prefix=" "
                hide-details="auto"
                persistent-placeholder
                placeholder="z.B. Muss [1] U [2] ein"
                :hint="getHintMessage(expressionInput)"
                @click="handleClick"
                @input="
                  handleInput(
                    expressionInput,
                    selectedEdifactFormatVersion,
                    selectedEdifactFormat
                  )
                "
                :disabled="!selectedEdifactFormat"
                @keydown.esc="hideSuggestions"
                @keydown.enter="hideSuggestions"
                ref="expressionInput"
              ></v-text-field>
              <div v-if="showSuggestions" class="suggestion-container">
                <v-list>
                  <v-list-item
                    v-for="(suggestion, index) in suggestions"
                    :key="index"
                    @click="selectSuggestion(suggestion)"
                    :class="{ selected: index === selectedSuggestionIndex }"
                    class="suggestion-item"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ suggestion }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-form>
          <v-btn
            class="info-button"
            small
            icon
            @click="showExpressionInfobox = true"
          >
          <img
              src="/css/hochfrequenz/icons/info.svg"
              alt="info"
              style="
                display: inline-block;
                width: 22px;
                height:22px;
                margin: 0 2px;
                filter: brightness(0) invert(1);
              "
            />
          </v-btn>

          <v-dialog v-model="showExpressionInfobox" width="600">
            <v-card>
              <v-toolbar elevation="0">
                <v-toolbar-title>AHB-Bedingung</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="showExpressionInfobox = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="text-body-1 text-left">
                Eine gültige AHB-Bedingung beginnt mit einem der folgenden
                Indikatoren: <code>Muss</code> <code>Soll</code>
                <code>Kann</code> <code>M</code> <code>S</code> <code>K</code>
                <code>X</code> <code>O</code> oder <code>U</code>. Der Rest des
                Bedingungsausdrucks darf nur die folgenden Zeichen enthalten:
                <code>[</code> <code>]</code> <code>(</code> <code>)</code>
                <code>U</code> <code>∧</code> <code>O</code> <code>∨</code>
                <code>X</code> <code>⊻</code> und Zahlen. Leerzeichen werden
                ignoriert.
                <br />
                Es können auch Pakete angegeben werden, dabei sollten sie einem
                der folgenden Formaten folgen: <code>32P</code>,
                <code>[32P]</code>, <code>[32P0..1]</code> oder
                <code>32P0..1</code>.
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <!-- Copy Link Button -->
      <div class="rounded-pill pill-button">
        <v-tooltip bottom :open-delay="0" :close-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="copyLinkToClipboard"
              :color="showCopySuccess ? 'success' : 'color'"
              v-bind="attrs"
              v-on="on"
            >
            <img v-if="!showCopySuccess"
              src="/css/hochfrequenz/icons/links.svg"
              alt="links"
              style="
                display: inline-block;
                width: 22px;
                height:22px;
                margin: 0 2px;
                filter: invert(0.20) sepia(0.48) saturate(1.99) hue-rotate(322deg);;
              "
            />
            <img v-if="showCopySuccess"
              src="/css/hochfrequenz/icons/haken3.svg"
              alt="info"
              style="
                display: inline-block;
                width: 22px;
                height:22px;
                margin: 0 2px;
                filter: invert(0.20) sepia(0.48) saturate(1.99) hue-rotate(322deg);;
              "
            />
            </v-btn>
          </template>
          <span>{{ showCopySuccess ? "Kopiert" : "Link kopieren" }}</span>
        </v-tooltip>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <nav class="navbar-collapse" id="navbar">
        <ul class="navbar-nav navbar-right" id="top-nav">
          <li v-if="$auth.isAuthenticated && $auth.loading === false">
            <div @click="logout" class="rounded-pill pill-button">
              Ausloggen
              <img
                src="/css/hochfrequenz/icons/logout.svg"
                alt="logout"
                width="20"
                height="20"
                class="ml-1"
                style="filter: invert(0.20) sepia(0.48) saturate(1.99) hue-rotate(322deg);"
              />
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { VTextField } from "vuetify/lib";
import {
  AvailableEdifactFormatVersions,
  EdifactFormatVersionData,
  EdifactFormatData,
  AvailableEdifactFormats,
  TreeData,
} from "@/services/ExpressionServices";
import ExpressionService from "@/services/ExpressionServices";
import ExpressionTree from "@/services/ExpressionServices";
import {
  ContentEvaluationResult,
  AhbExpressionEvaluationResult,
} from "ahbicht-ts-models";

@Component({
  name: "Navbar",
  components: {},
  props: ["hash"],
})
export default class Navbar extends Vue {
  data() {
    return {
      AvailableEdifactFormatVersions: AvailableEdifactFormatVersions,
      AvailableEdifactFormats: AvailableEdifactFormats,
    };
  }
  $refs!: {
    expressionInput: InstanceType<typeof VTextField>;
  };
  public loading = false;
  public showProgressBar() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 2000);
  }

  public login() {
    this.$auth.loginWithRedirect({});
  }

  public logout() {
    this.$auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  public expressionInput = "";
  public expressionTree: ExpressionTree = new ExpressionTree();
  public selectedEdifactFormat: EdifactFormatData = this.defaultEdifactFormat;
  public selectedEdifactFormatVersion: EdifactFormatVersionData =
    this.defaultEdifactFormatVersion;

  public suggestions: string[] = [];
  // If the user starts typing, they are shown these suggestions (which are generated from all AHB information we know in the backend).
  // We cannot bind the ExpressionService.fetchSuggestionsFromBackend(...) function to the input field directly, because it's async 🤷‍♂️
  // Although chatti claims, this shouldn't be a problem, Hamid and Konstantin ran into the error, that a promise was returned where an string[] was expected.
  // Writing the results into a property is a simple workaround to this issue.
  // Also it allows us to manipulate the suggestions from multiple places (instead of only one function). It's more complex, but also more flexible.

  public showSuggestions: boolean = false; // true if there are suggestions available AND the user hasn't selected one yet (after the last keystroke)
  public selectedSuggestionIndex: number = -1;
  public tempExpressionInput: string = "";

  get defaultEdifactFormat(): EdifactFormatData {
    return AvailableEdifactFormats[0];
  }
  get defaultEdifactFormatVersion(): EdifactFormatVersionData {
    return ExpressionService.getCurrentEdifactFormatVersionData();
  }

  @Watch("selectedEdifactFormatVersion")
  @Watch("selectedEdifactFormat")
  @Watch("expressionInput")
  onDataChange() {
    this.updateURL();
  }

  updateURL() {
    const newURL = this.generateUniqueLink();
    if (window.history.pushState) {
      window.history.pushState({ path: newURL }, "", newURL);
    } else {
      window.location.href = newURL;
    }
  }

  mounted() {
    this.selectedEdifactFormatVersion = this.defaultEdifactFormatVersion;
    this.selectedEdifactFormat = this.defaultEdifactFormat;

    // Parse query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const format = queryParams.get("format");
    const format_version = queryParams.get("format_version");
    const expression = queryParams.get("expression");

    if (format_version) {
      this.selectedEdifactFormatVersion =
        AvailableEdifactFormatVersions.find(
          (item: EdifactFormatVersionData) => item.value === format_version
        ) || this.defaultEdifactFormatVersion;
    }

    if (format) {
      this.selectedEdifactFormat =
        AvailableEdifactFormats.find(
          (item: EdifactFormatData) => item.value === format
        ) || this.defaultEdifactFormat;
    }

    if (expression) {
      this.expressionInput = decodeURIComponent(expression);
      this.evaluateExpression(this.expressionInput);
    }

    document.addEventListener("keydown", this.handleKeydown);
    document.addEventListener("click", this.handleOutsideClick);
  }

  // Generate the unique link with query parameters
  generateUniqueLink() {
    const params = new URLSearchParams();

    if (this.selectedEdifactFormatVersion.value) {
      params.append("format", this.selectedEdifactFormatVersion.value);
    }

    if (this.selectedEdifactFormat.value) {
      params.append("format_version", this.selectedEdifactFormat.value);
    }

    if (this.expressionInput) {
      params.append("expression", encodeURIComponent(this.expressionInput));
    }

    const queryString = params.toString();
    return `/tree/${queryString ? `?${queryString}` : ""}`;
  }

  public showCopySuccess = false;

  async copyLinkToClipboard() {
    const path = this.generateUniqueLink();
    const url = new URL(path, window.location.origin);

    try {
      if (navigator.clipboard && window.isSecureContext) {
        // For modern browsers
        await navigator.clipboard.writeText(url.toString());
      } else {
        // Fallback for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = url.toString();

        // Make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        // Use the deprecated execCommand for older browsers
        document.execCommand("copy");
        document.body.removeChild(textArea);
      }

      // Show success message
      this.showCopySuccess = true;
      setTimeout(() => {
        this.showCopySuccess = false;
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      this.showCopySuccess = true;
      setTimeout(() => {
        this.showCopySuccess = false;
      }, 2000);
    }
  }

  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener("click", this.handleOutsideClick);
    document.removeEventListener("keydown", this.handleKeydown);
  }

  async handleClick() {
    if (this.expressionInput.trim() && !this.showSuggestions) {
      await this.fetchSuggestions(
        this.expressionInput,
        this.selectedEdifactFormatVersion,
        this.selectedEdifactFormat
      );
      this.showSuggestions = true;
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (!this.showSuggestions) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        if (this.selectedSuggestionIndex < this.suggestions.length - 1) {
          this.selectedSuggestionIndex++;
          this.updateTempInput();
        }
        break;
      case "ArrowUp":
        event.preventDefault();
        if (this.selectedSuggestionIndex > -1) {
          this.selectedSuggestionIndex--;
          this.updateTempInput();
        }
        break;
      case "Enter":
        event.preventDefault();
        if (this.selectedSuggestionIndex > -1) {
          this.selectSuggestion(this.suggestions[this.selectedSuggestionIndex]);
        }
        break;
      case "Escape":
        this.hideSuggestions();
        break;
    }
  }

  updateTempInput() {
    if (this.selectedSuggestionIndex > -1) {
      this.tempExpressionInput = this.expressionInput;
      this.expressionInput = this.suggestions[this.selectedSuggestionIndex];

      // Scroll the selected item into view
      this.$nextTick(() => {
        const container = document.querySelector(".suggestion-container");
        const selectedItem = document.querySelector(
          ".suggestion-item.selected"
        );

        if (container && selectedItem) {
          const containerRect = container.getBoundingClientRect();
          const selectedRect = selectedItem.getBoundingClientRect();

          // Check if the selected item is outside the visible area
          if (selectedRect.bottom > containerRect.bottom) {
            // Scroll down if the item is below the visible area
            selectedItem.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          } else if (selectedRect.top < containerRect.top) {
            // Scroll up if the item is above the visible area
            selectedItem.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        }
      });
    } else {
      this.expressionInput = this.tempExpressionInput || this.expressionInput;
    }
  }

  public hideSuggestions() {
    this.showSuggestions = false;
    this.selectedSuggestionIndex = -1;
    this.tempExpressionInput = "";
  }

  public async selectSuggestion(suggestion: string): Promise<void> {
    this.expressionInput = suggestion;
    this.hideSuggestions();
    await this.evaluateExpression(this.expressionInput);
  }

  handleOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const inputElement = this.$refs.expressionInput.$el as HTMLElement;
    if (inputElement && !inputElement.contains(target)) {
      this.hideSuggestions();
    }
  }

  public conditionTextsAreShown = true;
  public expressionService = new ExpressionService();
  public counterToRefresh = 0;
  public requirmentConstraintFulfilled: boolean | null = null;
  public isFulfilled: boolean | null = null;
  public isUnfulfilled: boolean | null = null;
  public nodeNumber: string = "";
  public contentEvaluationResult: ContentEvaluationResult = {
    hints: {},
    requirement_constraints: {},
    format_constraints: {},
  };
  public ahbExpressionEvaluationResult: AhbExpressionEvaluationResult = {};
  public showFormatVersionInfobox = false;
  public showMessageFormatInfobox = false;

  public showEdifactFormatInfobox = false;

  public toggleEdifactFormatInfobox(): void {
    this.showEdifactFormatInfobox = !this.showEdifactFormatInfobox;
  }

  public showExpressionInfobox = false;

  public toggleExpressionInfobox(): void {
    this.showExpressionInfobox = !this.showExpressionInfobox;
  }

  public dialog = false;

  public async processUserInput(expInput: string): Promise<string> {
    return expInput.trim();
  }

  public async evaluateExpression(expression: string): Promise<void> {
    const proccessedExpression = await this.processUserInput(expression);
    if (expression.trim().length == 0) {
      this.$store.state.treeData = new TreeData(
        proccessedExpression,
        this.expressionTree,
        this.selectedEdifactFormat,
        this.selectedEdifactFormatVersion
      );
      return;
    }
    this.expressionTree = await this.expressionService.getExpressionTree(
      proccessedExpression,
      this.selectedEdifactFormatVersion,
      this.selectedEdifactFormat
    );
    this.$store.state.treeData = new TreeData(
      proccessedExpression,
      this.expressionTree,
      this.selectedEdifactFormat,
      this.selectedEdifactFormatVersion
    );
  }

  // fetched suggestions from backend and updates the property 'suggestions' with data retrieved
  public async fetchSuggestions(
    expressionInput: string,
    format_version: EdifactFormatVersionData,
    format: EdifactFormatData
  ): Promise<void> {
    this.suggestions = await this.expressionService.fetchSuggestionsFromBackend(
      expressionInput,
      format_version,
      format
    );
    // set show suggestions to true if there are suggestions available
    this.showSuggestions = this.suggestions.length > 0;
  }

  // all the stuff that happens while the user is typing something into the expression input field
  public async handleInput(
    expressionInput: string,
    format_version: EdifactFormatVersionData,
    format: EdifactFormatData
  ): Promise<void> {
    this.selectedSuggestionIndex = -1;
    await this.evaluateExpression(expressionInput);
    // we could think of only fetching suggestions when format_version and format are given.
    // because only then, the condition nodes can be resolved.
    // Alternatively, the backend could return information about _where_ the expression comes from (e.g. a MSCONS FV2404 AHB), then we could also update the selectedEdifactFormat(Version) in selectSuggestion.
    // so instead of ["Muss [1]", "Muss [1] U [2]"] the backend should return something like [{"format": "MSCONS", "format_version": "FV2404", "expression": "Muss [1]"}, {"format": "INVOIC", "format_version": "FV2310", "expression": "Muss [1] U [2]"}]
    // then, the backend should also find the most appropriate format, which is hard if an expression occurs in more than 1 ahb
    await this.fetchSuggestions(expressionInput, format_version, format);
  }

  getHintMessage(expressionInput: any): string | null {
    if (this.showSuggestions) {
      return null;
    }
    const emptyPackagePattern = /^(1[pP]|\[1[pP]\])$/;
    if (emptyPackagePattern.test(expressionInput)) {
      return `Das Paket ${expressionInput} ist leer, nach bdew-mako.de`;
    }
    if (this.expressionTree.code === 422 || this.expressionTree.code === 500)
      return "Keine gültige AHB expression";
    if (this.expressionTree.code === 404)
      return `Das Paket ${expressionInput} ist nicht vorhanden.`;
    return null;
  }

  // it is a result of a user evaluation sent from frontend
  public async sendParseExpressionPostReq(input: string): Promise<void> {
    this.ahbExpressionEvaluationResult =
      await this.expressionService.evaluateCondition(
        this.contentEvaluationResult,
        input
      );
    if (
      this.ahbExpressionEvaluationResult
        .requirement_constraint_evaluation_result
    ) {
      console.log(
        "requirement constraint evaluation result: ",
        this.ahbExpressionEvaluationResult
          .requirement_constraint_evaluation_result
          .requirement_constraints_fulfilled
      );
    } else {
      console.log("konnte noch nicht final ausgewertet werden");
    }
    if (
      this.ahbExpressionEvaluationResult
        .requirement_constraint_evaluation_result
        ? this.ahbExpressionEvaluationResult
            .requirement_constraint_evaluation_result
            .requirement_constraints_fulfilled === true
        : ""
    ) {
      this.requirmentConstraintFulfilled = true;
    } else if (
      this.ahbExpressionEvaluationResult
        .requirement_constraint_evaluation_result
        ? this.ahbExpressionEvaluationResult
            .requirement_constraint_evaluation_result
            .requirement_constraints_fulfilled === false
        : ""
    ) {
      this.requirmentConstraintFulfilled = false;
    }
  }
}
</script>

<style>
#navigation.navbar-default {
  background-color: var(--grell-gelb) !important;
  display: flex;
  align-items: center;
  min-height: 70px;
}

#navigation .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-header {
  display: flex;
  align-items: center;
  height: 70px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  text-decoration: none;
  color: var(--weiches-schwarz) !important;
  font-size: 1.25rem;
}

.navbar-logo {
  height: 24px;
  width: auto;
}

#navigation .navbar-collapse {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

#navigation .navbar-nav {
  display: flex;
  align-items: center;
  margin: 0;
  height: 70px;
}

#navigation .navbar-nav > li {
  display: flex;
  align-items: center;
  height: 100%;
}

#navigation .pill-button {
  background-color: var(--pastell-gelb);
  color: var(--weiches-schwarz) !important;
  
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  letter-spacing: normal;
}

#navigation .pill-button:hover {
  background-color: var(--pastell-gelb);
  opacity: 0.9;
  color: var(--weiches-schwarz) !important;
}

#navigation .pill-button .v-btn.v-btn--icon {
  height: 100%;
  width: auto;
  padding: 0;
}

#navigation .pill-button .v-btn.v-btn--icon .v-icon {
  font-size: 20px;
}

.input-fields-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex: 1;
  justify-content: center;
  margin: 0 32px;
}

.input-field-container {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
}

.input-field {
  flex: 1;
  background: var(--pastell-gelb) !important;
  border: 2px solid white !important;
  border-radius: 6px !important;
}
.input-field .v-input__slot {
  background: var(--pastell-gelb) !important;
  box-shadow: none !important;
  min-height: 42px !important;
  border: none !important;
  outline: none !important;
  padding-top: 6px !important;
}
.input-field .v-input__slot fieldset {
  border: none !important;
}
.input-field .v-select__slot {
  cursor: pointer !important;
}
.input-field .v-select__selection {
  color: rgba(0, 0, 0, 0.8) !important;
}
.input-field .v-label {
  transform: translateY(-22px) scale(0.75) !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  background: white !important;
  padding: 0 4px !important;
  z-index: 1 !important;
  border-radius: 4px !important;
}
.input-field-container:last-child {
  min-width: 430px;
  flex: 2;
}
.input-field-container:last-child .input-field {
  min-width: 410px;
}
.suggestion-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
}

.suggestion-item {
  cursor: pointer;
  text-align: left;
}

.suggestion-item:hover,
.suggestion-item.selected {
  background-color: #f5f5f5;
}

.navbar-brand span {
  letter-spacing: normal;
}
</style>
