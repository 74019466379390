import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Auth0Plugin } from "./auth";
import "./assets/tailwind.css";
import "../public/css/main.css";

// don't think the secret is necessary (let's research and discuss later)
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  scope: process.env.VUE_APP_AUTH0_SCOPE,
  clientSecret: process.env.VUE_APP_AUTH0_CLIENT_SECRET,

  onRedirectCallback: (appState: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    if (appState && appState.isLoggedIn) {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    } else {
      router.push({
        path: "/tree",
        query: Object.fromEntries(queryParams.entries()),
      });
    }
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
