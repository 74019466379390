/** "VueAuth.ts is a wrapper Vue object around auth0-spa-js, so we can leverage the observability provided by Vue" */
import { Vue, Component } from "vue-property-decorator";
import {
  PopupLoginOptions,
  Auth0Client,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import { createAuth0Client } from "@auth0/auth0-spa-js";

import { User } from "./User";
import jwtDecode from "jwt-decode";

export type Auth0Options = {
  domain: string;
  clientId: string;
  audience?: string;
  scope?: string;
  [key: string]: string | undefined;
};

export type RedirectCallback = (appState: any) => void;

@Component({})
export class VueAuth extends Vue {
  public loading = true;
  public isAuthenticated = false;
  public user?: User;
  public auth0Client?: Auth0Client;
  public popupOpen = false;
  public error?: Error | unknown;
  public grantedPermissions: string[] = [];

  public async setGrantedPermissionsFromToken() {
    // parses the JWT and reads the scopes from there
    try {
      const accessToken: string =
        (await this.auth0Client?.getTokenSilently()) || "";
      const decoded = jwtDecode<any>(accessToken, { header: true });

      // Once we figure out how to get more than one scope we should readd decoded.scope.split(" ")
      this.grantedPermissions = decoded.scope;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * "getUser is a simple wrapper around the authClient's getUser method.
   * We pass on the resolved promise to our User's constructor to create
   * a nicely looking User object"
   */
  public async getUser() {
    if (this.isAuthenticated) {
      await this.setGrantedPermissionsFromToken();
    }
    // this as User silenced the error but i don't understand what it does
    return new User((await this.auth0Client?.getUser()) as User);
  }

  /** Authenticates the user using a popup window */
  public async loginWithPopup(o: PopupLoginOptions) {
    this.popupOpen = true;

    try {
      await this.auth0Client?.loginWithPopup(o);
    } catch (e) {
      console.error(e);
      this.error = e;
    } finally {
      this.popupOpen = false;
    }

    this.user = await this.getUser();
    this.isAuthenticated = true;
  }

  /** Authenticates the user using the redirect method */
  public loginWithRedirect(o: RedirectLoginOptions) {
    return this.auth0Client?.loginWithRedirect(o);
  }

  /** Returns all the claims present in the ID token */
  public getIdTokenClaims() {
    return this.auth0Client?.getIdTokenClaims();
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  public getTokenSilently(o?: GetTokenSilentlyOptions) {
    return this.auth0Client?.getTokenSilently(o);
  }

  /** Gets the access token using a popup window */
  public getTokenWithPopup(o: GetTokenWithPopupOptions) {
    return this.auth0Client?.getTokenWithPopup(o);
  }

  /** Logs the user out and removes their session on the authorization server */
  public logout(o: LogoutOptions) {
    return this.auth0Client?.logout(o);
  }

  /** Use this lifecycle method to instantiate the SDK client */
  public async init(
    onRedirectCallback: RedirectCallback,
    redirectUri: string,
    auth0Options: Auth0Options
  ) {
    this.auth0Client = await createAuth0Client({
      domain: auth0Options.domain,
      clientId: auth0Options.clientId,
      useRefreshTokens: true,
      cacheLocation: "localstorage",
      authorizationParams: {
        redirect_uri: redirectUri,
        audience: auth0Options.audience,
        scope: auth0Options.scope,
      },
    });

    try {
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } =
          (await this.auth0Client?.handleRedirectCallback()) ?? {
            appState: undefined,
          };

        // Restore the original URL (including query params)
        const targetUrl =
          appState?.targetUrl ||
          window.location.pathname + window.location.search;
        window.history.replaceState({}, document.title, targetUrl);
        onRedirectCallback(appState);
      }
    } catch (e) {
      console.error(e);
      this.error = e;
    } finally {
      this.isAuthenticated = await this.auth0Client?.isAuthenticated();
      this.user = await this.getUser();
      this.loading = false;
    }
  }
}
