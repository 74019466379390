<template>
  <v-app>
    <Navbar v-if="!hideNavbar" />
    <router-view />
    <AppFooter :hideSolutionFooter="hideSolutionFooter" />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import AppFooter from "./components/LowerFooter.vue";
import ExpressionService from "@/services/ExpressionServices";
export default {
  name: "App",
  components: { Navbar, AppFooter }, // Updated component name
  computed: {
    hideNavbar() {
      return this.$route.name === "StartPage" || this.$route.name === "About";
    },
    hideSolutionFooter() {
      return this.$route.name === "StartPage";
    },
  },
  created() {
    this.greetDevelopers();
    this.warmUpBackend();
  },
  methods: {
    greetDevelopers() {
      // prints a little advertising message to the console log
      console.log(
        "  _______________________________________\n" +
          "/ Willkommen bei AHahnB! Wenn du das hier \\\n" +
          "| siehst hast Du wahrscheinlich Interesse |\n" +
          "| an Software-Entwicklung 👩‍💻👨‍💻 Wir bei   |\n" +
          "| Hochfrequenz suchen immer nach          |\n" +
          "| talentierten Entwickler*innen, die uns  |\n" +
          "| helfen, den deutschen Energiemarkt mit  |\n" +
          "| innovativen Lösungen zum Besseren zu    |\n" +
          "| verändern. Wenn es dir ähnlich geht und |\n" +
          "| Du nur noch das passende Team suchst:   |\n" +
          "\\  https://www.hochfrequenz.de/karriere   /\n" +
          "  ---------------------------------------\n" +
          "      \\   \n" +
          "       \\    //\n" +
          "        \\ <o)\n" +
          "            (\\\\_//\n" +
          "            (_\\_/\n" +
          "             _|_"
      );
      // todo: exchange cow with rooster/hahn
      // verweis auf weitere informationen/co-dev und so
      console.log(
        "Wenn Du nur wissen willst, wie diese Anwendung under the hood funktioniert oder z.b. die Auswertung der Ausdrücke in dein eigenes System integrieren willst: Melde dich gerne über das Kontaktformular! Wir haben fertige, stateless Docker Container am Start, die du einfach in deine bestehende IT-Infrastruktur System einbinden kannst: Z.B. so: https://github.com/Hochfrequenz/AhbichtClient.net/"
      );
    },
    async warmUpBackend() {
      // calls the backend once on FE start
      // This is to make the first response on the /tree site faster.
      // This is necessary for a good UX because the backend shuts down if not used (it's an Azure Function).
      // Re-starting from the cold state takes some seconds, maily because it reads all Anwendungshandbuecher first.
      const expressionService = new ExpressionService();
      const formatVersion =
        ExpressionService.getCurrentEdifactFormatVersionData().value;
      try {
        await expressionService.fetchSuggestionsFromBackend(
          "Muss",
          formatVersion,
          "UTILMD"
        );
        console.debug("Backend is hot now");
      } catch (error) {
        console.error("Failed to heat up backend:", error);
      }
    },
  },
};
</script>

<style>
* {
  font-family: Roboto, sans-serif;
}
:root {
  /* copied from companystylesheet: https://github.com/Hochfrequenz/companystylesheet/blob/main/css/hochfrequenz.css */
  --pastell-gelb: #f4e2b9;
  --grell-gelb: #e5bd5c;
  --off-white: #e7e6e5;
}
body {
  background-color: var(--pastell-gelb);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--pastell-gelb);
}
a {
  text-decoration: none;
}
.greenButton {
  background-color: #669580;
}
.yellowButton:hover {
  background-color: var(--grell-gelb);
}
.redButton {
  background-color: gray;
}
.redButton:hover {
  background-color: #b9351d;
}
.outlinedButton {
  background-color: gray;
}
.outlinedButton:hover {
  background-color: var(--grell-gelb);
}
</style>
